<template>
  <v-container fluid>


 
    <v-row class="pt-4 pb-4  pb-sm-16">
      <v-col cols=12  class='d-flex justify-center d-md-none'>
        <div id="div-gpt-ad-1651756349185-0"></div>  
      </v-col>
    </v-row>
  
    
      <v-row no-gutters>
        <v-col cols="12" class="pt-0 pt-md-16"
               sm="12"
               md="12"
               lg="12"
               xl="12"
               xs="12">
          <p class="central-text pt-xs-0 pt-sm-0" :class="textSize">Leita [´lei:ta] : “to seek, to search”</p>
        </v-col>
      </v-row>

      
    
  </v-container>

</template>

<script>
export default {
  name: 'HeadText',
  computed: {
    textSize() {
      // console.log(this.$vuetify.breakpoint.mobile);
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return "head-central-text-xs";
        case 'sm':
          return "head-central-text-sm";
        default : return "central-text"

          // case 'xs': return "content-central-text-mobile"
          // case 'sm': return "content-central-text-mobile"
          // case 'md': return 170
          // case 'lg': return 170
          // case 'xl': return 170
      }
    },
  },
  mounted() {
    
    if(this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {
      
      window.googletag = window.googletag || {cmd: []};
      window.googletag.cmd.push(function() {
        window.googletag.defineSlot('/21806670553/LEITA_MobileBottom_320x50', [320, 50], 'div-gpt-ad-1651756349185-0').addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
      });

      window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1651756349185-0'); }); 
    }   

  },
}
</script>