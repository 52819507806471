<template>
  <div>
      <div class="pb-0 pb-sm-16">
        <HeadText></HeadText>
        <SearchForm></SearchForm>
        <div class="pt-8 pb-8">
          <HomeContent></HomeContent>
        </div>
      </div>
      <div>
          <FooterLink></FooterLink>
      </div>
      <Footer></Footer>
  </div>
</template>
<script>
// import HelloWorld from './components/HelloWorld';

import HeadText from "../components/home/HeadText";
import SearchForm from "../components/home/SearchForm";
import HomeContent from "../components/home/HomeContent";
import FooterLink from "../components/home/FooterLink";
import Footer from "../components/home/Footer";

export default {
  name: 'Home',

  components: {
    Footer,
    FooterLink,
    HomeContent,
    SearchForm,
    // Hello/World,
    HeadText,
  },

  data: () => ({
    //
  }),
};
</script>
