<template>
  <v-container class="pb-15" style="overflow-y: scroll">
    <v-row class="pb-10">

      <v-col cols="12" sm="4">
        <v-row>
          <v-col col="12" md="10" class="d-flex justify-center">
            <a @click="navigate('/about-us','source')" style="text-decoration: none;">
            <span class="bell-text" :class="fontSize"> 
              <v-icon color="#206297">
                 mdi-server
              </v-icon>  Resources </span>
            </a>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col col="12" md="10">
            <hr class="footer-link-hr">
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4">
        <v-row>
          <v-col class="d-none d-md-block" md="1" lg="1" xl="1">
          </v-col>
          <v-col sm="12" md="10" lg="10" xl="10" class="d-flex justify-center">
            <a @click="navigate('/about-us', 'about_us')" style="text-decoration: none;">
            <span class="bell-text" :class="fontSize"> 
              <v-icon color="#206297">
                mdi-message-alert-outline
              </v-icon> About Leita </span>
            </a>
          </v-col>
          <v-col class="d-none d-md-block" md="1" lg="1" xl="1">
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="d-none d-md-block" md="1" lg="1" xl="1">
          </v-col>
          <v-col sm="12" md="10" lg="10" xl="10">
            <hr class="footer-link-hr">
          </v-col>
          <v-col class="d-none d-md-block" md="1" lg="1" xl="1">
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4" class="pb-10 pb-sm-0">
        <v-row>
          <v-col class="d-none d-md-block" sm="2" md="2" lg="2" xl="2">
          </v-col>
          <v-col sm="12" md="10" lg="10" xl="10" class="d-flex justify-center">
            <router-link to="/subscribe" style="text-decoration: none;">
            <span class="bell-text" :class="fontSize">
              <v-icon color="#206297">
                mdi-bell-ring-outline
              </v-icon>Subscribe to newsletter</span>
            </router-link>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="d-none d-md-block" md="2" lg="2" xl="2">
          </v-col>
          <v-col sm="12" md="10" lg="10" xl="10">
            <hr class="footer-link-hr">
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

</template>


<script>
export default {
  name: 'FooterLink',
  props: {
    type: String,
  },
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return "sm-font-size"
        default:
          return "bell-text"
          // case 'md': return 170
          // case 'lg': return 170
          // case 'xl': return 170
      }
    },

  },
  methods: {
    navigate(page, to) {
      if (this.type == 'self_navigation') {
        this.$root.$emit("go_to", to);
      } else {
        this.$router.push(page + '?to=' + to);
      }

    }
  }

}
</script>